import { Cross2Icon, TrashIcon } from "@radix-ui/react-icons";
import { useSearchParams } from "@remix-run/react";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Box, Button, Card, Flex, MultiSelect, Text } from "sparta";
import { useTracking } from "~/utils/featureFlags.client";
import { logger } from "~/utils/logger.client";

const ensureString = (value: unknown): string => {
  if (value === null || value === undefined) return '';
  return typeof value === 'string' ? value : String(value);
};

const sanitizeArrayValues = (arr: unknown[]): string[] => {
  return arr.map(item => ensureString(item)).filter(Boolean);
};

interface IProps {
  filters: any[];
  onChange: ({ category }: { category: string }) => void;
  isLoading: boolean;
  searchOptions: any;
}

export interface IFiltersInputText {
  label: string; // Mandatory, unique value. Input.
  value: string; // Mandatory, unique value. Input.
  type: "text" | "multiselect" | "filtercard" | "date" | "check"; // Add more types if needed. Input.
  selected: boolean; // Input. Output.
  category: string; // Mandatory, unique value. Input.
  placeholder: string; // Mandatory.
  defaultValue: string; // Input.
  inputValue: string; // Input. Output.
  subtype?: string; // Input.
  selectedPhrases?: string[]; // Input. Output.
}

export interface IFiltersInputFiltercard {
  label: string; // Mandatory, unique value. Input.
  value: string; // Mandatory, unique value. Input.
  type: "text" | "multiselect" | "filtercard"; // Add more types if needed. Input.
  selected: boolean; // Input. Output.
  category: string; // Mandatory, unique value. Input.
  placeholder: string; // Mandatory.
  items: any[]; // Input.
  selectedValues: any[]; // Input.
  displayType: "badge" | "number"; // Input.
}

export interface IFiltersCheckedInput {
  type: "checkbox"; // Add more types if needed. Input.
  checked: boolean;
  today: Date;
  label: string; // Mandatory, unique value. Input.
  value: string; // Mandatory, unique value. Input.
  selected: boolean;
  category: string;
}

export interface IFilterDateRangeInput {
  label: string;
  value: string;
  type: "date-range";
  selected: boolean;
  mindatecategory: string;
  maxdatecategory: string;
  items: any[];
  selectedValues: any[];
  category: string;
  singleDateMin?: Date;
  singleDateMax?: Date;
}

export const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

export default function Filters({ filters, onChange, isLoading, searchOptions }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialState, setInitialState] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [resetFeature, setResetFeature] = useState<boolean>(true);
  const [appliedChanges, setAppliedChanges] = useState<boolean>(true);
  const tracking = useTracking();

  useEffect(() => {
    if (filters) {
      setInitialState(_.clone(filters));
      setSelectedFilters(_.clone(filters.filter((x) => x.selected)));
    }
  }, []);

  useEffect(() => {
    const resetFilters: boolean = filters.some(
      (x) =>
        searchParams.getAll(x.category).length > 0 ||
        searchParams.getAll(x.mindatecategory).length > 0 ||
        searchParams.getAll(x.maxdatecategory).length > 0,
    );
    setResetFeature(!resetFilters);
    setAppliedChanges(true);
  }, [searchParams]);

  const resetPageToOne = () => {
    setSearchParams((prev) => {
      if (prev.getAll("page")) {
        prev.delete("page");
      }

      return prev;
    });
  };

  const resetFilter = (category) => {
    const filterToReset = selectedFilters.find((x) => x.category === category);

    setSearchParams((prev) => {
      if (
        prev.getAll(filterToReset?.category).length > 0 &&
        !filterToReset?.maxdatecategory &&
        !filterToReset?.mindatecategory
      ) {
        prev.delete(category);
        onChange({ category });
      }
      if (filterToReset?.maxdatecategory && filterToReset?.mindatecategory) {
        prev.delete(filterToReset?.maxdatecategory);
        prev.delete(filterToReset?.mindatecategory);
        onChange({ category });
      }
      return prev;
    });
    setSelectedFilters((prev) => {
      return prev.filter((x) => x.category !== category);
    });
    resetPageToOne();
  };

  const resetFilters = () => {
    selectedFilters.forEach((x) => {
      if (x.mindatecategory && x.maxdatecategory) {
        setSearchParams((prev) => {
          if (prev.getAll(x.mindatecategory).length > 0) {
            prev.delete(x.mindatecategory);
            onChange({ category: x.mindatecategory });
          }
          if (prev.getAll(x.maxdatecategory).length > 0) {
            prev.delete(x.maxdatecategory);
            onChange({ category: x.maxdatecategory });
          }
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          if (prev.getAll(x.category).length > 0) {
            prev.delete(x.category);
            onChange({ category: x.category });
          }
          return prev;
        });
      }
    });
    setSelectedFilters([]);
    setAppliedChanges(true);
    resetPageToOne();
  };

  const clearFilter = (category) => {
    if (category) {
      const aux = _.clone(
        selectedFilters.map((x) => {
          if (category === x.category) {
            x.selectedValues = [];
            setSearchParams((prev) => {
              if (prev.getAll(x.category).length > 0) {
                prev.delete(x.category);
                onChange({ category: x.category });
              }
              return prev;
            });
          }
          if (category.includes("response_deadline")) {
            if (x.checked) {
              x.checked = false;
            }
            setSearchParams((prev) => {
              if (prev.getAll("response_deadline_after").length > 0) {
                prev.delete("response_deadline_after");
                onChange({ category: "response_deadline_after" });
              }
              if (prev.getAll("response_deadline_before").length > 0) {
                prev.delete("response_deadline_before");
                onChange({ category: "response_deadline_before" });
              }
              return prev;
            });
          }
          return x;
        }),
      );
      setSelectedFilters(aux);
      resetPageToOne();
    } else {
      logger.error("Error while trying to clear filter: ", { category });
    }
  };

  const clearFilters = () => {
    setSelectedFilters(_.clone(filters.filter((x) => x.selected)));
    setAppliedChanges(true);
  };

  const searchFilter = (value: string) => {
    const searchedData = _.clone(
      filters.filter((x) => {
        return x.label.toLowerCase().includes(value.toLowerCase());
      }),
    );
    setInitialState(!!value ? searchedData : _.clone(filters));
  };

  const toogleState = (value) => {
    const filter = selectedFilters.find((x) => x.value === value);

    if (!!filter) {
      setSelectedFilters(_.clone(selectedFilters.filter((x) => x.value !== value)));
      resetFilter(filter.category);
      if (filter.category === "response_deadline_after") {
        setSearchParams((prev) => {
          prev.delete("response_deadline_after");
          onChange({ category: "response_deadline_after" });
          return prev;
        });
      }
    } else {
      const selectedFilter = _.clone(filters.find((x) => x.value === value));
      selectedFilter.selectedValues = [];
      setSelectedFilters((prev) => [...prev, { ...selectedFilter }]);
      if (selectedFilter.category === "response_deadline_after") {
        setSearchParams((prev) => {
          prev.set("response_deadline_after", new Date().toLocaleDateString());
          onChange({ category: "response_deadline_after" });
          return prev;
        });
      }
    }
  };

  const addFilterValue = (value: unknown, category: string, items: any[], valueName?: string) => {
    if (!!value && !!category) {
      const aux = _.clone(
        selectedFilters.map((x) => {
          if (x.category === category) {
            if (x.type === "text") {
              const safeValue = ensureString(value);
              x.inputValue = safeValue.trim();
              
              if (!!x.subtype && x.subtype === "key-phrase") {
                const safeSelectedPhrases = Array.isArray(x.selectedPhrases) ? 
                  sanitizeArrayValues(x.selectedPhrases) : [];
                
                if (safeSelectedPhrases.includes(safeValue)) {
                  x.selectedPhrases = safeSelectedPhrases.filter(
                    item => item !== safeValue
                  );
                } else {
                  x.selectedPhrases = [...safeSelectedPhrases, safeValue];
                }
                x.inputValue = "";
              }
            }

            // Keep existing filtercard logic
            if (x.type === "filtercard") {
              x.items = items.map((y) => {
                if (y.value.toString() === String(value)) {
                  const finded = x.selectedValues.find(
                    (b) => b.value.toString() === String(value)
                  );
                  if (!!finded) {
                    x.selectedValues = x.selectedValues.filter(
                      (b) => b.value.toString() !== String(value)
                    );
                  } else {
                    x.selectedValues.push(y);
                  }
                  y.selected = !y.selected;
                }
                return y;
              });
            }

            // Keep existing checkbox and date-range logic
            if (x.type === "checkbox") {
              x.checked = !x.checked;
            }

            if (x.type === "date-range") {
              x[valueName] = value;
            }
          }
          return x;
        })
      );

      setSelectedFilters(_.clone(aux));
      setAppliedChanges(false);
    }
  };

  const applyFilters = () => {
    // Initialize params per iteration

    if (selectedFilters.length > 0) {
      // Iterate over selected filters at the moment
      // biome-ignore lint/complexity/noForEach: <explanation>
      selectedFilters.forEach((x) => {
        // Review filter type
        // Type text
        if (x.type === "text") {
          if (x.category) {
            // Review if the iterated filter category exists in params
            if (searchParams.getAll(x.category).length > 0) {
              // Delete all previous values in the category
              searchParams.delete(x.category);
            }

            if (x.subtype && x.subtype === "key-phrase") {
              if (Array.isArray(x.selectedPhrases) && x.selectedPhrases.length > 0) {
                const safeSelectedPhrases = sanitizeArrayValues(x.selectedPhrases);
                safeSelectedPhrases.forEach((y) => {
                  setSearchParams((prev) => {
                    prev.append(x.category, y);
                    onChange({ category: x.category });
                    return prev;
                  });
                });
              }
            } else {
              const safeInputValue = ensureString(x.inputValue);
              setSearchParams((prev) => {
                prev.append(x.category, safeInputValue);
                onChange({ category: x.category });
                return prev;
              });
            }
          } else {
            logger.error("Error while trying to apply filter of type Text. Attributes: ", {
              value: x.inputValue,
              category: x.category,
            });
          }
        }

        // Type filtercard
        if (x.type === "filtercard") {
          if (!!x.selectedValues && !!x.category) {
            // Review if the iterated filter category exists in params
            if (searchParams.getAll(x.category).length > 0) {
              // Delete all previous values in the category
              searchParams.delete(x.category);
            }
            // Review if the filter have selected values
            if (x.selectedValues?.length > 0) {
              // Iterate the selected values
              // biome-ignore lint/complexity/noForEach: <explanation>
              x.selectedValues.forEach((y) => {
                setSearchParams((prev) => {
                  // Append new params values to search in the category
                  prev.append(x.category, y.value.toString());
                  return prev;
                });
              });
              onChange({ category: x.category });
            }
          }
        }
        // Type checkbox Active Opportunity
        if (!!x.checked && x.category === "response_deadline_after" && x.label === "Active Opportunity") {
          setSearchParams((prev) => {
            searchParams.set(x.category, new Date().toLocaleDateString());

            return prev;
          });
        }
        if (!x.checked && x.category === "response_deadline_after" && x.label === "Active Opportunity") {
          setSearchParams((prev) => {
            searchParams.delete(x.category);

            return prev;
          });
        }
        // Type date-range
        if (x.type === "date-range") {
          if (x.singleDateMin) {
            setSearchParams((prev) => {
              if (prev.getAll(x.mindatecategory).length > 0) {
                prev.delete(x.mindatecategory);
              }
              const dataDate = formatDataDate(x.singleDateMin);
              prev.set(x.mindatecategory, dataDate);
              return prev;
            });
            onChange({ category: x.mindatecategory });
          }
          if (x.singleDateMax) {
            setSearchParams((prev) => {
              if (prev.getAll(x.maxdatecategory).length > 0) {
                prev.delete(x.maxdatecategory);
              }
              const dataDate = formatDataDate(x.singleDateMax);
              prev.set(x.maxdatecategory, dataDate);
              return prev;
            });
            onChange({ category: x.maxdatecategory });
          }
        }
      });
      tracking.featureUsed("search-opportunities", { searchParams: searchParams.toString() });
      resetPageToOne();
    }
  };

  return (
    <Card style={{ padding: 0, width: "100%" }} variant="surface" shadow={false}>
      {/** Filters Dropdown */}
      <Flex
        align="center"
        gap="3"
        p="4"
        style={{
          borderBottom: "1px solid var(--gray-5)",
        }}
        width={"100%"}
        maxWidth={"100%"}
      >
        <Box>
          <MultiSelect
            options={initialState}
            selectedValues={selectedFilters}
            onOptionToggle={toogleState}
            onSearchChange={searchFilter}
            onClear={() => resetFilters()}
            placeholder="Add Filters"
            shouldFilter={true}
          />
        </Box>
        <Button
          loading={isLoading}
          disabled={resetFeature || isLoading}
          variant="outline"
          color="red"
          onClick={() => resetFilters()}
        >
          <TrashIcon />
          Reset
        </Button>
      </Flex>
      {/** Filters UI */}
      {selectedFilters.length > 0 && (
        <Flex
          direction="column"
          gap="2"
          p="4"
          height="auto"
          style={{
            transition: "ease",
          }}
        >
          <AnimatePresence mode={"sync"}>
            {selectedFilters.map((item) => {
              const isActive: boolean = isDateInFuture(item?.singleDateMin);
              if (item.label === "Active Opportunity") return null;
              {
                /** All non date components */
              }
              return (
                <motion.div
                  layout
                  initial={{ scale: 0.98, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  transition={{ type: "spring", duration: 0.25, ease: [0.54, 0.42, 0.03, 1] }}
                  style={{ zIndex: 1 }}
                  key={item.label}
                >
                  <Flex key={item.label} align="center" justify="between">
                    <Flex direction="column">
                      {isActive && <Flex width="100%" height="10px" />}
                      <Flex align="center" justify="start" gap="4">
                        <Text wrap="nowrap">{item.label}</Text>
                        <Button onClick={() => resetFilter(item.category)} variant="ghost" color="red">
                          <Cross2Icon />
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex width="100%" align="center">
                      {searchOptions(item, addFilterValue, clearFilter)}
                    </Flex>
                  </Flex>
                </motion.div>
              );
            })}
          </AnimatePresence>
          <Flex align="center" justify="end">
            <Button
              style={{ zIndex: 2 }}
              loading={isLoading}
              disabled={appliedChanges || isLoading}
              onClick={applyFilters}
            >
              Search
            </Button>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}

export const isDateInFuture = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to start of the day
  return new Date(date).getTime() >= today.getTime();
};

export const formatDataDate = (date) => {
  const newDate = new Date(date);
  const year: number = newDate.getFullYear();
  const dayAux: number = newDate.getDate();
  const monthAux: number = newDate.getMonth() + 1;
  let month: number = monthAux;
  let day: number = dayAux;

  if (monthAux < 10) {
    month = parseInt(`0${monthAux}`);
  }

  if (dayAux < 10) {
    day = parseInt(`0${dayAux}`);
  }
  return `${year}-${month}-${day}`;
};
